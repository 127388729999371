.contact{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 2rem;
}

.sendButton{
    font-size: 110%;
    padding: 1rem;
    border-radius: 1rem;
    border:  none;
    background: #2557a7;
    color: white;
    width: 40rem;
    cursor: pointer;
}