*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html{
  font-size: 62.5%; /*1rem = 10px*/
  scroll-behavior: smooth;
}

body{
  font-family: "Noto Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
}

