.search__form{
    width: 100%;
    justify-content: center;
    display: flex;
    padding: 2rem;
}

.search__div{
    display: flex;
    gap: 2rem;
    flex-direction: column;
    width: 50%;
    border: 1px solid #d4d2d0;
    border-radius: 1rem;
    box-shadow: 0 0.125rem 0.25rem rgb(45 45 45 / 12%), 0 0.0625rem 0.1875rem rgb(45 45 45 / 16%), 0 0 0.125rem rgb(45 45 45 / 20%);
    padding: 1rem;
}

.search__label{
    font-size: 200%;
}

.search__input{
    font-size: 110%;
    padding: 1rem;
    border-radius: 1rem;
    border: #2557a7 1px solid;
    width: 100%;
}

.search__button{
    font-size: 110%;
    padding: 1rem;
    border-radius: 1rem;
    border:  none;
    background: #2557a7;
    color: white;
    width: 100%;
}

@media screen and (max-width: 1000px) {
    .search__div{
        width: 100%;
    }    
}