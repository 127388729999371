.login__article {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    align-items: center;
    padding-left: 40%;
    padding-right: 40%;
}

.login__section{
    width: 40rem;
    height: 50rem;
    flex-direction: column;
    padding: 2rem;
    gap: 1rem;
    border: 1px solid #d4d2d0;
    border-radius: 1rem;
    box-shadow: 0 0.125rem 0.25rem rgb(45 45 45 / 12%), 0 0.0625rem 0.1875rem rgb(45 45 45 / 16%), 0 0 0.125rem rgb(45 45 45 / 20%);
    display: flex;
    flex-direction: column;
}

.login__header{
    font-size: 110%;
    font-weight: 600;
}

.login__input{
    font-size: 110%;
    padding: 1rem;
    border-radius: 1rem;
    border: #2557a7 1px solid;
}

.login__button{
    font-size: 110%;
    padding: 1rem;
    border-radius: 1rem;
    border:  none;
    background: #2557a7;
    color: white;
    width: 100%;
    cursor: pointer;
}
