.navigationBar__ul{
    display: flex;
    list-style: none;
    flex-direction: row;
    justify-content: space-around;
    border-bottom-color: rgb(228, 226, 224);
    border-bottom-style: solid;
    border-bottom-width: 0.666667px;
    align-items: center;
    min-height: 7.2rem;
    flex-shrink: 0;
}

.navigationBar__Link {
    text-decoration: none;
    color: black;
}

.navigationBar__Link--button {
    font-size: 100%;
    padding: 0.5rem;
    height: 100%;
    border: none;
    background: none;
    cursor: pointer;
    border-bottom: 0.2rem solid #00000000;
    height: 7.2rem;
    font-family: "Noto Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 1.4rem;
}

.navigationBar__Link--button:hover {
    border-bottom: 0.2rem solid #2557a7;
}

.logo {
    height: 3rem;
    cursor: pointer;
}

.navigationBar--login {
    color: #2557a7;
    font-weight: bold;

}

@media screen and (max-width: 470px) {
    .navigationBar__ul{
        flex-direction: column;
    }

    .navigationBar__Link--button{
        padding: 0;
    }
    
}