.cards{
    width: 40rem;
    height: 60rem;
    flex-direction: column;
    border: 1px solid #d4d2d0;
    border-radius: 1rem;
    box-shadow: 0 0.125rem 0.25rem rgb(45 45 45 / 12%), 0 0.0625rem 0.1875rem rgb(45 45 45 / 16%), 0 0 0.125rem rgb(45 45 45 / 20%);
}

.cards__img{
    height: 60%;
    width: 100%;
    object-fit: cover;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.cards__article{
    height: 40%;
    padding: 1rem;
}

.cards__h1{
    text-align: center;
    font-size: 150%;
}

.cards__p{
    padding: 1rem;
}

