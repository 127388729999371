@media screen and (min-width: 840px) {
    .collectionCardsWrapper{
        display: flex;
    }
    
    .collectionCardsInfo{
        width: 50%;
        height: 100%;
        flex-shrink: 0;
    }
    
    .collectionCards{
        width: 50%;
        height: 100%;
    }
}

@media screen and (max-width: 840px){
    .collectionCardsWrapper{
        display: flex;
        flex-direction: column;
    }
    
    .collectionCardsInfo{
        width: 100%;
        padding-bottom: 2rem;
    }
    
    .collectionCards{
        width: 100%;
    }
}