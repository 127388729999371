.article{
    font-size: 100%;
    padding: 1rem;
}

.title{
    padding-bottom: 0.5rem;
}

.input{
    font-size: 110%;
    padding: 1rem;
    border-radius: 1rem;
    border: #2557a7 1px solid;
    width: 40rem;
}